(function ($) {
	'use strict';

	var THEMENAME = {

		init: function () {
			let _this = this;
			// this.themeChooser();
			this.mainMenuBlock();
			this.submenuOpener();
			this.headerSearch();
			this.fixedHeader();
			this.mediaSlider();
			this.newsSlider();
			this.postSlider();
			// this.customerSlider();
			// this.customerSlider1();
			this.storySlider();
			this.socialSlider();
			this.countDown();
			this.numberCounter();
			// this.tooltipPlacement();
			this.scrollAnimation();
			// this.linkHeight();
		},

		themeChooser: function () {
			if(localStorage.getItem('theme-mode') === "false" || localStorage.getItem('theme-mode') === "" || localStorage.getItem('theme-mode') === undefined) {
				jQuery('#theme-chooser').prop('checked', false);
				jQuery('html').removeClass('theme-dark').addClass('theme-default');
			}else {
				jQuery('#theme-chooser').prop('checked', true);
				jQuery('html').removeClass('theme-default').addClass('theme-dark');
			}

			jQuery('.theme-switcher').on('change', '#theme-chooser', function() {
				if(this.checked) {
					jQuery('html').removeClass('theme-default').addClass('theme-dark');
					localStorage.setItem('theme-mode', "true");
				}else {
					jQuery('html').removeClass('theme-dark').addClass('theme-default');
					localStorage.setItem('theme-mode', "false");
				}
			});
		},

		mainMenuBlock: function () {
			jQuery('header').on('click', '.navbar-toggle-btn', function (e){
				e.preventDefault();
				if(!jQuery(this).hasClass("nav-open")) {
					jQuery('#navbar').removeClass('active');
					jQuery('html').removeClass('menu-active');
					jQuery(this).removeClass('nav-close').addClass('nav-open');
					//         jQuery('header').removeClass('nav-close').addClass('nav-open');

				} else {
					jQuery('#navbar').addClass('active');
					jQuery('html').addClass('menu-active');
					jQuery(this).removeClass('nav-open').addClass('nav-close');
				}
			});
		},

		submenuOpener : function () {
			jQuery( ".navbar-nav .submenu-opener" ).on('click', function( e ) {
				// e.preventDefault();
				if ( !jQuery( this ).hasClass( 'closed' ) ) {
					jQuery( this ).addClass( 'closed' );
					jQuery(this).parent().addClass('activeNav');
					// jQuery( this ).next('ul.dropdown-menu').slideToggle();
				}
				else {
					jQuery( this ).removeClass( 'closed' );
					jQuery(this).parent().removeClass('activeNav');
					// jQuery( this ).next('ul.dropdown-menu').slideToggle();
				}

			});

		},

		headerSearch : function () {
			jQuery( ".header-search a" ).on('click', function( e ) {
				// e.preventDefault();
				jQuery( '.search-form-desktop' ).slideToggle();
				jQuery( 'body' ).toggleClass('search-open');

			});

			jQuery( ".search-form-wrapper .close-button" ).on('click', function( e ) {
				// e.preventDefault();
				jQuery( '.search-form-desktop' ).slideToggle();
				jQuery( 'body' ).toggleClass('search-open');

			});

		},

		fixedHeader : function () {
			jQuery(window).scroll(function(){
				var sticky = jQuery('header'),
					scroll = jQuery(window).scrollTop();

				if (scroll >= 30) sticky.addClass('sticky-header');
				else sticky.removeClass('sticky-header');
			});
		},

		mediaSlider : function () {

			var swiper = new Swiper(".media-slider", {
				slidesPerView: 2,
				spaceBetween: 30,
				centeredSlides: false,
				loop: false,
				mousewheelControl: true,
				scrollbar: {
					el: ".swiper-scrollbar",
					draggable: true,
				},
				navigation: {
					nextEl: ".news-swiper-button-next",
					prevEl: ".news-swiper-button-prev",
				},
				pagination: {
					el: ".news-swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 1.3
					},
					768: {
						slidesPerView: 2
					},
					1200: {
						slidesPerView: 2
					}
				}
			});
		},

		newsSlider : function () {

			var swiper = new Swiper(".news-slider", {
				slidesPerView: 3,
				spaceBetween: 30,
				centeredSlides: false,
				loop: false,
				scrollbar: {
					el: ".news-swiper-scrollbar",
					draggable: true,
				},
				navigation: {
					nextEl: ".news-swiper-button-next",
					prevEl: ".news-swiper-button-prev",
				},
				pagination: {
					el: ".news-swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 1.3
					},
					768: {
						slidesPerView: 2
					},
					1200: {
						slidesPerView: 3
					}
				}
			});
		},

		postSlider : function () {
			var swiper = new Swiper(".post-slider", {
				slidesPerView: 2,
				spaceBetween: 30,
				centeredSlides: false,
				loop: false,
				scrollbar: {
					el: ".post-swiper-scrollbar",
					draggable: true,
				},
				navigation: {
					nextEl: ".news-swiper-button-next",
					prevEl: ".news-swiper-button-prev",
				},
				pagination: {
					el: ".news-swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 1.3
					},
					768: {
						slidesPerView: 2
					},
					1200: {
						slidesPerView: 2
					}
				}
			});

		},







		storySlider : function () {
			var swiper = new Swiper(".story-slider", {
				slidesPerView: 1,
				spaceBetween: 30,
				centeredSlides: false,
				loop: true,

				navigation: {
					nextEl: ".news-swiper-button-next",
					prevEl: ".news-swiper-button-prev",
				},
				pagination: {
					el: ".news-swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 1.3
					},
					768: {
						slidesPerView: 1
					}
				}

			});

		},

		socialSlider : function () {
			var swiper = new Swiper(".social-wrapper", {
				slidesPerView: 5,
				spaceBetween: 15,
				centeredSlides: false,
				loop: false,

				navigation: {
					nextEl: ".social-swiper-button-next",
					prevEl: ".social-swiper-button-prev",
				},
				pagination: {
					el: ".social-swiper-pagination",
					clickable: true,
				},
				breakpoints: {
					0: {
						slidesPerView: 2.2
					},
					768: {
						slidesPerView: 3
					},
					992: {
						slidesPerView: 4
					},
					1200: {
						slidesPerView: 5
					}
				}
			});

		},

		customerSlider : function () {
			jQuery('.customer-slider').slick({
				slidesToShow: 4,
				slidesToScroll: 1,
				adaptiveHeight: true,
				infinite: true,
				autoplay: true,
				arrows: false,
				dots: false,
				responsive: [
					{
						breakpoint: 1200,
						settings: {
							slidesToShow: 3,
						}
					},
					{
						breakpoint: 768,
						settings: {
							slidesToShow: 2,
						}
					},
				]
			});
		},

		customerSlider1 : function () {
			var swiper = new Swiper(".swiper-top", {
				spaceBetween: 100,
				centeredSlides: true,
				speed: 4000,
				autoplay: {
					delay: 1,
				},
				loop: true,
				slidesPerView:'auto',
				allowTouchMove: true,
				disableOnInteraction: true,
				preventInteractionOnTransition: true,
			});
		},

		countDown: function () {
			jQuery(function(){

				countDown.init();
				for (var i=0;i<countDown.validElements.length;i++) {
					countDown.changeTime(countDown.validElements[i],countDown.endDate[i],i);
				};
				setInterval(function(){
					for (var i=0;i<countDown.validElements.length;i++) {
						countDown.changeTime(countDown.validElements[i],countDown.endDate[i],i);
					};
				},1000);
			});

			var countDown = {
				endDate : [],
				validElements : [],
				display : [],
				initialHeight : undefined,
				initialInnerDivMarginTop : undefined,
				originalBorderTopStyle : undefined,

				init : function(element,number) {
					jQuery('.countDown').each(function(index){
						var regex_match = jQuery(this).text().match(/([0-9]{1,2})\/([0-9]{1,2})\/([0-9]{4}) ([0-9]{2}):([0-9]{2}):([0-9]{2})/);
						countDown.validElements.push(jQuery(this));
						var end = new Date(regex_match[3], regex_match[2] - 1, regex_match[1], regex_match[4], regex_match[5], regex_match[6]);

						if(end > new Date()) {
							countDown.endDate.push(end);
							countDown.changeTime(jQuery(this),end,index);
							jQuery(this).html("");

							for (var i=0;i<countDown.display.next.length;i++) {

								if(i == countDown.display.next.length -1) {
									jQuery(this).append("<div class='timer-container'><div class='timer-container-wrapper'><div>" + countDown.display.next[i]);
								} else {
									jQuery(this).append("<div class='timer-container'><div class='timer-container-wrapper'><div>" + countDown.display.next[i]);
								}
							}

						} else {
							// jQuery(this).html("<p class='end'>Der Countdown ist bereits abgelaufen.</p>");
						}
					});
				},

				reset : function(element,number) {
					var element1 = element.find('div.a');
				},

				changeTime : function(element, endTime, elementIndex) {
					if (typeof endTime !== 'undefined') {
						var today = new Date();

						if(today.getTime() <= endTime.getTime()) {
							today = new Date();
							countDown.display = {
								'last' : [],
								'next' : []
							};

							var seconds = Math.floor((endTime.getTime() - today.getTime()) / 1000)+1;
							countDown.display.last = countDown.calcTime(seconds);
							seconds = Math.floor((endTime.getTime() - today.getTime()) / 1000);
							countDown.display.next = countDown.calcTime(seconds);

							for (var i=0;i<countDown.display.next.length;i++) {
								if(countDown.display.next[i].toString().length == 1) {
									countDown.display.next[i] = '0' + countDown.display.next[i];
								}

								if(countDown.display.last[i].toString().length == 1) {
									countDown.display.last[i] = '0' + countDown.display.last[i];
								}

								jQuery(element.find('div.timer-container div.timer-container-wrapper div')[i]).text(countDown.display.last[i]);
								countDown.reset(element.find('div.timer-container'));
							}
						} else {
							// element.html("<p class='end'>Der Countdown ist bereits abgelaufen.</p>");
						}
					}
				},

				calcTime : function(seconds) {
					var array = [];
					array[0] = Math.floor(seconds / 86400);
					seconds -= array[0] * 86400;
					array[1] = Math.floor(seconds / 3600);
					seconds -= array[1] * 3600;
					array[2] = Math.floor(seconds / 60);
					seconds -= array[2] * 60;
					array[3] = seconds;
					return [array[0],array[1],array[2],array[3]];
				}
			}
		},

		numberCounter: function () {
			var a = 0;
			$(window).scroll(function() {

				var oTop = $('#counter').length && $('#counter').offset().top - window.innerHeight;
				if (a == 0 && $(window).scrollTop() > oTop) {
					$('.counter-value').each(function() {
						var $this = $(this),
							countTo = $this.attr('data-count');
						$({
							countNum: $this.text()
						}).animate({
								countNum: countTo
							},

							{

								duration: 3000,
								easing: 'swing',
								step: function() {
									$this.text(Math.floor(this.countNum));
								},
								complete: function() {
									$this.text(this.countNum);
									//alert('finished');
								}

							});
					});
					a = 1;
				}

			});
		},

		tooltipPlacement: function () {
			$(function() {
				// mouse-on example
				var mouseOnDiv = $('#spot1');
				var tipContent = $(
					'<p><b>Here is some content</b></p>'
				);
				mouseOnDiv.data('powertipjq', tipContent);
				mouseOnDiv.powerTip({
					placement: 'e',
					mouseOnToPopup: true
				});
			});
		},

		scrollAnimation: function () {
			let ST = window.ScrollTrigger;
			window.gsap.registerPlugin(ST);
			// jQuery('#rocket').css('height', jQuery(window).innerHeight() + 'px')

			/*
			* Parallax animation
			* */

			window.gsap.utils.toArray("[data-module-parallax]").forEach((section) => {
				const parallax = section.querySelector("[data-parallax]");
				const depth = parallax.dataset.speed;
				const movement = -(parallax.offsetHeight * depth);

				window.gsap.fromTo(
					parallax,
					{
						y: -movement
					},
					{
						y: movement,
						ease: "none",
						scrollTrigger: {
							trigger: section,
							scrub: true,
							onlyInViewport: true,
							invalidateOnRefresh: true
						}
					}
				);
			});

			/*
			* Network Station animation
			* */
			let timelineNetwork = window.gsap.timeline({
				scrollTrigger: {
					trigger: ".network-station-section .text-block",
					scrub: 1,
					start: "top top",
					end: "+=1500 0",
					ease: 'none',
				}
			});

			timelineNetwork
				.to(".antenna", {
					scale: 1.4,
					x: '0%'
				}, "sameTime")
				.to(".antenna-bg", {
					scale: 1.6,
					y: '100%'
				}, "sameTime")


			/*
			* Rocket animation
			* */

			function LottieScrollTrigger(vars) {
				let playHead = {frame: 0},
					target = window.gsap.utils.toArray(vars.target)[0],
					speeds = {slow: "+=5000", medium: "+=1000", fast: "+=500"},
					st = {trigger: target, pin: true, start: "top top", end: speeds[vars.speed] || "+=1000", scrub: 1},
					animation = lottie.loadAnimation({
						container: target,
						renderer: vars.renderer || "svg",
						loop: false,
						autoplay: false,
						path: vars.path
					});
				for (let p in vars) {
					st[p] = vars[p];
				}
				animation.addEventListener("DOMLoaded", function() {
					window.gsap.to(playHead, {
						frame: animation.totalFrames - 1,
						ease: "none",
						onUpdate: () => animation.goToAndStop(playHead.frame, true),
						scrollTrigger: st
					});
				});
			}

			function animation() {
				const animationContainer = jQuery('.rocket-section')
				LottieScrollTrigger({
					target: animationContainer,
					path: 'images/rocket/data.json',
					speed: 'medium',
					scrub: 1,
				})
			}
			document.addEventListener("DOMContentLoaded", function(e) {
				window.onbeforeunload = function () {
					window.scrollTo(0, 0);
				}
				window.onload = function() {
					window.requestAnimationFrame(function() {
						animation();
					})
				}
			})

			/*
			* Satellite Section
			* */
			let top = '0%';

			ST.matchMedia({
				"(min-width: 1921px)": function () {
					top = '-10%';

				},
				"(max-width: 1920px)": function () {
					top = '-50%';
				},
				"(max-width: 1440px)": function () {
					top = '-45%';
				},
				"(max-width: 1280px)": function () {
					top = '-30%';
				},
				"(max-width: 1024px)": function () {
					top = '-100%';
				},
				"(max-width: 991px)": function () {
					top = '-50%';
				}
			})

			let timelineSatellite = window.gsap.timeline({
				scrollTrigger: {
					trigger: ".satellite img",
					scrub: true,
					start: ".first-section "+top+"",
					end: "+=1800 0",
					ease: "none",
					invalidateOnRefresh: true,
					toggleActions: "play none none reverse",
				}
			});

			let _scaleFrom=1.6,_scaleTo=1, _xFrom,_xTo, _yFrom,_yTo;

			ST.matchMedia({
				"(min-width: 992px)": function () {
					_scaleFrom= 1.6;
					_scaleTo= 0.6;
					_xFrom='0%';
					_xTo='-80%';
					_yFrom='0%'
					_yTo='200%';
				},
				"(max-width: 991px)": function () {
					_scaleFrom= 1.2;
					_scaleTo= 0.6;
					_xFrom='0%';
					_xTo='-10%';
					_yFrom='0%'
					_yTo='245%';
				}
			})
			ST.refresh(true);

			timelineSatellite.set(".satellite img", {scale: _scaleFrom, x: _xFrom, y: _yFrom})
			timelineSatellite.to(".satellite img", {scale: _scaleTo, x: _xTo, y: _yTo})


			// jQuery(window).bind("resize",function(e){
			// 	ST.refresh(true);
			// });

			window.gsap.set(".zoom-section .zoom-image img", {scale: 1.6})
			window.gsap.to(".zoom-section .zoom-image img", {scale: 1.1, scrollTrigger: {
					trigger: ".zoom-section .zoom-image",
					start: ".zoom-section",
					// end: '+=1800',
					end: () => `+=${jQuery('.zoom-section').innerHeight() * 1.5 }`,
					scrub: 1,
					ease: 'none',
					onlyInViewport: true,
					invalidateOnRefresh: true
				}})
		},

		linkHeight: function() {
			jQuery(window).bind({
				load:function(){
					linkHeight();
				},
				resize:function(){
					linkHeight();
				},
				scroll:function(){
					linkHeight();
				}
			});

			function linkHeight() {
				var maxHeight = 0;

				jQuery(".mega-menu-parent li.current-menu-parent a").each(function(){
					if (jQuery(this).height() > maxHeight) { maxHeight = jQuery(this).height(); }
				});
				jQuery(".col-first").height(maxHeight);
				jQuery(".mega-menu-parent li.current-menu-parent>a").height(maxHeight);
			}
		},
	};

	THEMENAME.init();

})(jQuery);

